import api from "@/service/api";

const DISPLAY_API_BASE = '/api/display/'

class DisplayService {
    getDisplays() {
        return api.get(DISPLAY_API_BASE + 'all');
    }
}

export default new DisplayService()