import { createRouter, createWebHashHistory } from 'vue-router'
import ParkingView from '@/views/ParkingView';
import ParkingListView from '@/views/ParkingListView';
import LoginView from "@/views/LoginView";
import {authGuard} from "@/auth";

const routes = [
  { path: '/', redirect: { name: "login" }},
  { path: '/parkings', name: 'parkings', component: ParkingListView, guar: authGuard },
  { path: "/login", name: "login", component: LoginView },
  { path: '/detail/:id', name: 'detail', component: ParkingView }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
