import {createApp} from 'vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primeicons/primeicons.css';
import router from './router'
import store from './store'
import App from './App.vue'

const app = createApp(App);

app.use(router);
app.use(store);
app.mount('#app');
