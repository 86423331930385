
//import call from "@/service/http";
import api from "@/service/api";

const USER_API_BASE = '/api/user/info'

class AuthenticationService {

    login(value) {
        return api.get(USER_API_BASE + '?accesscode=' + value);
    }

    logout() {
        localStorage.removeItem('watcherPasscode');
    }


}

export default new AuthenticationService()