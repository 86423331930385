import {authenticated} from "@/store/getters";
import AuthenticationService from "../service/AuthenticationService";

export const authGuard = (to, from, next) => {

    const fn = () => {
        // If the user is authenticated, continue with the route
        if (authenticated()) {
            return next();
        }

        // Otherwise, log in
        AuthenticationService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    };


    // Watch for the loading property to change before checking isAuthenticated
    AuthenticationService.$watch('loading', (loading) => {
        if (loading === false) {
            return fn();
        }
    });
};