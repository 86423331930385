<template>
  <div class="container">
    <h1 class="text-center">Parkings &nbsp;
      <i class="pi pi-refresh" style="font-size: 1.5rem" v-on:click="getParkings()"></i>
    </h1>
    <table class="table table-hover">
      <thead>
        <th style="text-align: left">Parking</th>
        <th>Belegt</th>
        <th>Total</th>
      </thead>
      <tbody>
        <tr v-for="parking in parkings" v-bind:key = 'parking.parkingKey' v-on:click="selectParking(parking)">
          <td style="text-align: left"> {{ parking.name }}</td>
          <td> {{ parking.occupancyPercentage }} %</td>
          <td> {{ parking.total }}</td>
        </tr>
      </tbody>
    </table>

    <br>
    <h1 class="text-center">Displays &nbsp;</h1>
    <div>
      <a v-for="display in displays" v-bind:key = 'display.id' v-on:click="openDisplay(display.url)">{{ display.name }}&nbsp;&nbsp;&nbsp;&nbsp;</a>
    </div>


  </div>
</template>

<script>
  import ParkingService from "../service/ParkingService";
  import DisplayService from "@/service/DisplayService";
  /* eslint-disable */

  export default {
    name: 'ParkingList',
    data(){
      return {
        parkings: [],
        displays: [],
      }
    },
    methods: {
      getDisplays(){
        DisplayService.getDisplays().then( response => {
          this.displays = response.data;
        }, error => {
          this.$router.replace({ name: "login" });
        })
      },

      getParkings(){
        ParkingService.getParkings().then( response => {
          this.parkings = response.data;
        }, error => {
          this.$router.replace({ name: "login" });
        })
      },

      selectParking(parking) {
        this.$router.push({ name: 'detail' , params: { id: parking.parkingKey }});
      },

      openDisplay(url) {
        window.open(url);
      }

    },
    created() {
      this.getParkings()
      this.getDisplays()
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

a{
  cursor: pointer;
}
</style>
