<template>
  <div class="container">
    <h1 class="text-center">Parking {{ parking?.name }}</h1>

    <div class="d-grid gap-5 col-5 mx-auto">
      <button type="button" class="btn btn-success btn-lg" v-on:click="updateOccupancyPercentage(25)">25 %</button>
      <button type="button" class="btn btn-secondary btn-lg" v-on:click="updateOccupancyPercentage(50)">50 %</button>
      <button type="button" class="btn btn-warning btn-lg" v-on:click="updateOccupancyPercentage(75)">75 %</button>
      <button type="button" class="btn btn-danger btn-lg" v-on:click="updateOccupancyPercentage(95)">95 %</button>
    </div>

  </div>
</template>

<script>
  import ParkingService from "@/service/ParkingService";
  /* eslint-disable */

  export default {
    name: 'ParkingView',
    data(){
      return {
        parking: null
      }
    },
    methods: {
      getParking(id) {
        ParkingService.getParking(id).then( response => {
          this.parking = response.data;
        })
      },
      updateOccupancyPercentage(value) {
        if (this.parking.occupancyPercentage === value) {
          console.log('no change => ignoring');
          this.$router.push({ name: 'parkings'});
          return;
        }

        let data = {
          nameExternal: this.parking.nameExternal,
          occupancyPercentage: value
        };
        ParkingService.updateOccupancyPercentage(data).then( response => {
          this.$router.push({ name: 'parkings'});
        })
      }
    },
    created() {
      const id =  this.$route.params.id;
      console.log('created', id);
      this.getParking(id);
    }}

</script>

