import api from "@/service/api";

const PARKING_API_BASE = '/api/parking/'

class ParkingService {
    getParkings() {
        return api.get(PARKING_API_BASE + 'all');
    }

    getParking(id) {
        return api.get( PARKING_API_BASE + id);
    }

    updateOccupancyPercentage(any) {
        return api.put( PARKING_API_BASE + 'updateOccupancyPercentage', any);
    }

}

export default new ParkingService()