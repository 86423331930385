<template>
  <div id="login">
    <h1>Login</h1>
    <input type="text" name="passcode" v-model="input.passcode" placeholder="Passcode" />
    <button type="button" :disabled="input.passcode === ''" v-on:click="login()">Login</button>
    <div id="error" v-if="error">Ungültiger Code</div>
  </div>
</template>

<script>
import AuthenticationService from "@/service/AuthenticationService";

export default {

  name: 'LoginView',
  data() {
    return {
      input: {
        passcode: ""
      },
      error: false
    }
  },
  methods: {
    login() {
      if(this.input.passcode != "" ) {
        AuthenticationService.login(this.input.passcode).then( () => {
          console.log('login succeded');
          localStorage.setItem('watcherPasscode', this.input.passcode);
          this.$store.dispatch('login');
          this.$router.replace({ name: "parkings" });
        }, error => {
          console.log('login failed');
          this.error = true;
          console.error(error);
        });

      }
    },
    silentLogin() {
      const passcode = localStorage.getItem('watcherPasscode');
      if (passcode) {
        AuthenticationService.login(passcode);
        this.$store.dispatch('login');
        this.$router.replace({ name: "parkings" });
      }
    }
  },
  created() {
    this.silentLogin();
  }
}
</script>

<style scoped>
#login {
  width: 500px;
  border: 1px solid #CCCCCC;
  background-color: #FFFFFF;
  margin: auto;
  margin-top: 200px;
  padding: 20px;
}
#error {
  border: 2px solid darkred;
  background-color: lightgray;
  margin: 15px;
  padding: 20px;
}

</style>