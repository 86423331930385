import axios from 'axios'

const api = axios.create({
    baseURL: process.env.VUE_APP_URL_API
})

api.interceptors.request.use((config) => {
    const accesscode = localStorage.getItem('watcherPasscode')

    config.headers.accesscode = `${accesscode}`

    return config
})

//api.interceptors.response.use(success, failure)

export default api