<template>
  <nav class="navbar navbar-expand navbar-light jf-color">
    <a class="navbar-brand text-white" href="#">
      <img src="./assets/Jungfrau_Logo_cmyk.png" width="50"/>
    </a>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <router-link class="nav-link text-light" to="/parkings">Home</router-link>
        </li>
        <li class="nav-item active" v-if="$store.getters.authenticated" >
          <a class="nav-link text-light" @click="logout()">Logout</a>
        </li>
      </ul>
    </div>
  </nav>
  <div class="container" @authentication-event="setAuthenticated">
    <router-view/>
  </div>
  <nav class="navbar fixed-bottom navbar-light bg-light">
    <a class="navbar-brand" href="#"></a>
  </nav>
</template>

<script>
import AuthenticationService from "@/service/AuthenticationService";

export default {
  name: 'App',
  data() {
    return {
    }
  },
  methods: {
    logout() {
      AuthenticationService.logout();
      this.$store.commit('logout');
      this.$router.push({ name: "login" });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: white;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.jf-color {
  background-color: #354875;
  color: white;
}

.navbar-brand {
  padding-left: 1em;
}

.container {
  padding: 1em;
}

</style>
<script>
</script>