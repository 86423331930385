import api from "@/service/api";

export default {
    login (state) {
        state.authenticated = true;
        api.interceptors.request.use((config) => {
            const accesscode = localStorage.getItem('watcherPasscode')
            config.headers.accesscode = `${accesscode}`

            return config
        })
    },
    logout (state) {
        state.authenticated = false;
    }
}
